<template>
  <div style="background-color: #fff; padding: 16px">
    <a-form-model
      size="small"
      layout="inline"
      @keyup.native.enter="handleSearch()"
    >
      <a-form-model-item label="规格搜索">
        <a-input v-model="searchText" placeholder="请输入规格" allowClear />
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" type="primary" @click="handleSearch">
          搜索</a-button
        >
      </a-form-model-item>
    </a-form-model>
    <div class="mb20">
      <a-button type="primary" @click="showAddModal">添加规格</a-button>
    </div>

    <a-table :columns="columns" :data-source="tableData" :pagination="false">
      <template slot="goods_type" slot-scope="text">
        {{ goodsTypeData[text] }}
      </template>
      <template slot="action" slot-scope="text, record, index">
        <a-space>
          <a-button
            size="small"
            type="primary"
            @click="handleEdit(record, index)"
          >
            编辑
          </a-button>
          <a-popconfirm
            title="确定要删除吗?"
            @confirm="handleDelete(record, index)"
          >
            <a-button size="small" type="danger"> 删除 </a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>

    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      :title="isEdit ? '编辑规格' : '添加规格'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="dialogVisible = false"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <a-form-model-item label="商品类型">
          <span>{{ goodsTypeData[detailData.goods_type] }}</span>
        </a-form-model-item>
        <a-form-model-item label="规格名称">
          <span>{{ detailData.name }}</span>
        </a-form-model-item>
        <a-form-model-item label="规格" prop="spec">
          <a-input placeholder="请输入规格" v-model="form.spec" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { spec_edit } from "@/api/spec";

export default {
  data() {
    return {
      columns: [
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
        {
          title: "商品类型",
          dataIndex: "goods_type",
          key: "goods_type",
          scopedSlots: { customRender: "goods_type" },
        },
        {
          title: "规格名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "规格",
          dataIndex: "spec",
          key: "spec",
        },
      ],
      tableData: [], // 初识数据
      originalTableData: [], // 提交数据
      goodsTypeData: ["请选择", "种子", "农药", "苗", "肥料", "其他"],
      detailData: {},
      dialogVisible: false,
      submitLoading: false,
      isEdit: false,
      editIndex: -1,
      form: {
        spec: "",
      },
      rules: {
        spec: [{ required: true, message: "请输入规格", trigger: "blur" }],
      },
      searchText: "",
    };
  },
  created() {
    const detailStr = localStorage.getItem("spec_detail");
    if (detailStr) {
      this.detailData = JSON.parse(detailStr);

      if (!this.detailData.val) {
        this.originalTableData = [];
        this.tableData = [];
        localStorage.removeItem("spec_detail");
        return;
      }

      const specs = this.detailData.val.split(",");
      const validSpecs = specs.filter((spec) => spec.trim());

      if (validSpecs.length === 0) {
        this.originalTableData = [];
        this.tableData = [];
      } else {
        this.originalTableData = validSpecs.reverse().map((spec) => ({
          goods_type: this.detailData.goods_type,
          name: this.detailData.name,
          spec: spec,
        }));
        this.tableData = [...this.originalTableData];
      }

      localStorage.removeItem("spec_detail");
    } else {
      this.$message.error("数据不存在");
      this.$router.push("/spec/index");
    }
  },
  methods: {
    showAddModal() {
      this.isEdit = false;
      this.editIndex = -1;
      this.form.spec = "";
      this.dialogVisible = true;
    },
    handleEdit(record, index) {
      this.isEdit = true;
      this.editIndex = index;
      this.form.spec = record.spec;
      this.dialogVisible = true;
    },
    async handleDelete(record, index) {
      this.originalTableData.splice(index, 1);
      this.tableData = [...this.originalTableData];
      await this.updateSpecData();
      this.$message.success("删除成功");
    },
    async updateSpecData() {
      const submitData = {
        id: this.detailData.id,
        goods_type: this.detailData.goods_type,
        name: this.detailData.name,
        val: [...this.originalTableData]
          .reverse()
          .map((item) => item.spec)
          .join(","),
      };

      try {
        await spec_edit(submitData);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;

          if (this.isEdit) {
            this.originalTableData[this.editIndex].spec = this.form.spec;
          } else {
            this.originalTableData.unshift({
              goods_type: this.detailData.goods_type,
              name: this.detailData.name,
              spec: this.form.spec,
            });
          }
          this.tableData = [...this.originalTableData];

          try {
            await this.updateSpecData();
            this.dialogVisible = false;
          } catch (err) {
            console.error(err);
          } finally {
            this.submitLoading = false;
          }
        }
      });
    },
    handleSearch() {
      if (!this.searchText) {
        this.tableData = [...this.originalTableData];
      } else {
        this.tableData = this.originalTableData.filter((item) =>
          item.spec.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }
    },
  },
};
</script>
